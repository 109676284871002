import './App.css';
import ParticlesBackgrounds from './components/ParticlesBackgrounds';
import Hero from './components/Hero';
import Body from "./components/Body"

const App = () => {
    return(
      <>
      <ParticlesBackgrounds/>
      <div className='App'>
        <Hero/>
        <Body/>
      </div>

      </>
    );

   
};

export default App;
