import React from 'react'
import "./Hero.css"
export default function Hero() {
  return (
    <div className='Hero'>
        <h1 className='h1t'>
            Hello! My name is <span style={{color:"#ff4d5a"}}>Ville Vartia</span>.
        </h1>
        <h2>I am a web developer with a mission to create  <span style={{color:"#ff4d5a"}}>smooth</span>, <span style={{color:"#ff4d5a"}}>intuitive</span> and <span style={{color:"#ff4d5a"}}>scalable</span> web applications.</h2>
        <h3 className='h3t'>Take a look at some of my projects down below:</h3>
    </div>
  )
}
