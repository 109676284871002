import React from 'react'
import "./Body.css"
import Footer from './Footer';
import RM from "../img/RM.png"
import CITYBIKE from "../img/CITYBIKE.png"
import JAVA from "../img/java.png"
import HTML from "../img/html-5.png"
import CSS from "../img/css-3.png"
import JS from "../img/java-script.png"
import PYTHON from "../img/python-logo-only.png"
import GIT from "../img/Git-Icon-White.png"
import MONGODB from "../img/MongoDB_Logomark_ForestGreen.png"
import SQL from "../img/SQL.png"
import NODEJS from "../img/NODE.png"
export default function Body() {
  return (
    <div className='Body'>
      <h1 className='h1'>Tech stack</h1>
      <div className="diamond">
        {[JS,HTML,CSS,JAVA,PYTHON,NODEJS,MONGODB,SQL,GIT].map( 
          image => <img src={image} alt="picname"/>)}
      </div>

      <div className='imgContainer'>
          <h1 className='h1'>Projects</h1>
        <span style={{color:"#ff4d5a"}}> <h3>Helsinki Citybikes</h3></span>
          <p>Fullstack web application for examining interesting data on citybike journeys</p>
          <a
              href='https://helcitybikeapp.fly.dev'
              target={{}}>
              <img 
                className='imgBody'
                src={CITYBIKE}
                alt='Citybike app'/>
            </a>
        <span style={{color:"#ff4d5a"}}> <h3>Rocket Mania</h3></span>
          <p>Retro arcade mobile game with thousands of users and over four-star rating on Google Play Store</p>
            <a 
              href='https://play.google.com/store/apps/details?id=com.rocketmania.rakettipeli'
              target={{}}>
              <img 
                className='imgBody'
                src={RM}
                alt='Rocket Mania Google Play'/>
            </a>

        </div>
        <div className='contact'>
          <h1 className='h1'>Contact me</h1>
          <h4>Let's work <span style={{color:"#ff4d5a"}}>together</span></h4>
          <form 
            className='form' 
            action='https://formspree.io/f/mrgvqapk'
            method='POST'
            >
            <label className='label'>
              Name:<br/>
              <input type="text" name='name' placeholder='Your Name'/>
            </label>
            <br/>
            <label className='label'>
              Email:<br/>
              <input type="email" name='Email' placeholder='your.name@mail.com'/>
            </label>
            <br/>
            
            <textarea name='message' placeholder="Whats on your mind?" className='textarea'></textarea>
            <br/>
            <button type='submit' className='button'>Submit</button>
          </form>
        </div>
        
        <Footer/>
    </div>
  )
}
